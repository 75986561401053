<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template #activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="reset">
        Tambah
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-5">
        <span class="text-h5">{{ actionTitle }} {{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="rules.valid" :disabled="isLoading">
          <v-autocomplete
            v-model="form.account_code"
            :rules="rules.account_code"
            :items="accounts"
            :loading="isLoading"
            :search-input.sync="account_name"
            label="Akun"
            item-text="account_name"
            item-value="account_code"
            hide-details="auto"
            class="mb-3"
            outlined
          ></v-autocomplete>
          <v-text-field
            v-model="form.amount"
            :rules="rules.amount"
            label="Nilai"
            hide-details="auto"
            class="mb-3"
            outlined
          ></v-text-field>
          <v-textarea
            v-model="form.memo"
            label="Catatan"
            hide-details="auto"
            class="mb-3"
            outlined
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text large @click="close">
          Batal
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text large @click="submit">
          Simpan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    accounts: [],
    account_name: null,
    form: {
      account_code: null,
      amount: null,
      memo: null,
    },
    rules: {
      valid: true,
      account_code: [(v) => !!v || 'Akun harus dipilih'],
      amount: [(v) => !!v || 'Nominal harus diisi'],
    },
    actionTitle: 'Tambah',
    editIndex: null,
  }),
  async mounted() {
    this.isLoading = true
    await this.getAccounts()
    this.isLoading = false
  },
  methods: {
    async getAccounts() {
      await this.$http.get('/accounting/accounts').then((response) => {
        this.accounts = response.data
      })
    },
    open() {
      this.reset()
      this.actionTitle = 'Tambah'
      this.dialog = true
    },
    reset() {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.editIndex = null
      }
    },
    edit(item, index) {
      this.editIndex = index
      this.form.account_code = item.account_code
      this.form.amount = item.amount
      this.form.memo = item.memo
      this.actionTitle = 'Edit'
      this.dialog = true
    },
    close() {
      this.reset()
      this.dialog = false
    },
    submit() {
      if (this.editIndex !== null) {
        this.$emit('update', {
          ...this.form,
          account_name: this.account_name,
          index: this.editIndex,
        })
      } else {
        this.$emit('submit', { ...this.form, account_name: this.account_name })
      }

      this.dialog = false
    },
  },
}
</script>
